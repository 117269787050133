import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getImage } from "../../util";

const ImageSkeleton = ({
  src,
  width,
  style = {},
  className,
  imageStyle = {},
  skeletonClassName,
}) => {
  const [loadImage, setLoadimage] = useState(false);

  // handle load image
  const handleLoadImage = (e) => {
    setLoadimage(true);
  };

  useEffect(() => {
    window.getHeight(skeletonClassName, style);
  }, []);

  return (
    <div style={{ height: imageStyle?.height }}>
      {!loadImage ? (
        <Skeleton.Avatar
          style={{ width: "100%", height: "100%" }}
          shape="square"
          size={width || "default"}
          active
          className={skeletonClassName}
        ></Skeleton.Avatar>
      ) : (
        <LazyLoadImage
          src={src}
          effect="blur"
          placeholderSrc={getImage()}
          className={className}
          width="100%"
          style={imageStyle}
          height="auto"
        />
        // <img
        //   src={src}
        //   alt="product"
        //   width="100%"
        //   height="auto"
        //   style={imageStyle}
        //   className={className}
        // />
      )}
      <img
        className="d-none"
        src={src}
        alt="product"
        onLoad={handleLoadImage}
      />
      {/* <LazyLoadImage
          src={src}
          effect="blur"
          placeholderSrc={getImage()}
          className={className}
          width="100%"
          style={imageStyle}
          height="auto"
        /> */}
    </div>
  );
};

export default ImageSkeleton;
