import gql from "graphql-tag";
import { CORE_ELEMENT_PRODUCT } from "./fragment";

export const FETCH_PRODUCTS = gql`
  ${CORE_ELEMENT_PRODUCT}
  query FetchProduct($productId: ID) {
    FetchProduct(productId: $productId) {
      code
      success
      message
      result {
        ...CoreProductElement
      }
    }
  }
`;

export const FETCH_PRODUCTS_FROM_ID = gql`
  ${CORE_ELEMENT_PRODUCT}
  query FetchProducts(
    $subcategoryId: ID
    $categoryId: ID
    $offset: Int
    $limit: Int
    $filters: SingleSubcategoryFilterInput
  ) {
    FetchProducts(
      subcategoryId: $subcategoryId
      categoryId: $categoryId
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      code
      message
      subcategory {
        _id
        name
        sizeType
        products {
          ...CoreProductElement
        }
      }
      category {
        _id
        name
        sizeType
        products {
          ...CoreProductElement
        }
      }
      productCount
    }
  }
`;
export const FETCH_COLOR_WITH_COUNT = gql`
  query FetchColorList($categoryId: ID, $subcategoryId: ID) {
    FetchColorList(categoryId: $categoryId, subcategoryId: $subcategoryId)  {
      success
      results {
        colorName
        colorCode
        count
      }
    }
  }
`;

export const FETCH_RELATED_PRODUCT = gql`
  query FetchSimilerProduct(
    $subcategoryId: ID
    $categoryId: ID
    $crntProductId: ID
    $limit: Int
    $sizeType: ProductSizeType
  ) {
    FetchSimilerProduct(
      subcategoryId: $subcategoryId
      categoryId: $categoryId
      crntProductId: $crntProductId
      limit: $limit
      sizeType: $sizeType
    ) {
      result {
        _id
        name
        status
        price
        discount
        category {
          _id
          sizeType
        }
        subcategory {
          _id
          sizeType
        }
        price
        isAccessories
        topPrice
        bottomPrice
        haveDiscount
        gallery
        colors {
          _id
          topColor
          bottomColor
          topColorName
          bottomColorName
          gallery
          topGallery
          bottomGallery
        }
        isAvailable
        # sizeType
      }
    }
  }
`;

export const FETCH_SIZE_GUIDE = gql`
  query FetchSizeGuide($type: SizeGuideTypeEnum) {
    FetchSizeGuide(type: $type) {
      code
      message
      success
      result {
        _id
        type
        name
        measure_1
        measure_2
        createdAt
      }
    }
  }
`;
