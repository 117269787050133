import React, { Suspense, useEffect } from "react";
import { createBrowserHistory } from "history";
import { Switch, Route, Router } from "react-router-dom";

// Index Routes
import { Auth, Common, Private, Public } from "./Router";
import { AuthRoute, PrivateRoute, PublicRoute, CommonRoute } from "./AllRoute";
import { NotFound } from "../pages";
import Layout from "../containers/Layout";
import { FETCH_ONLY_CATEGORY } from "../graphql/modules";
import { useQuery } from "@apollo/react-hooks";
import { saveCategory } from "../store";
import { useDispatch } from "react-redux";
import SuspenseFallback from "../pages/SuspenseFallback";
// import ScrollToTop from "./ScrollToTop";
// import { useSelector } from "react-redux";
// import { useMutation } from "@apollo/react-hooks";
// import { CHECK_USER_TOKEN, } from "../graphql/modules";
// Routes

// Components
export const history = createBrowserHistory();

const RouterConfig = () => {
  // const code = useSelector((state) => state.auth.jwtToken);
  // useEffect(() => {
  //   if (code) {
  //     checkUserToken();
  //   }
  // }, [code]);

  // const [TokenCheckMutation] = useMutation(CHECK_USER_TOKEN, {
  //   variables: {
  //     code,
  //   },
  // });

  // const checkUserToken = async () => {
  //   try {
  //     const {
  //       data: { IsValidToken },
  //     } = await TokenCheckMutation();
  //     if (!IsValidToken.success) {
  //       dispatch(logoutUser());
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  return (
    <Router history={history}>
      <Suspense fallback={<SuspenseFallback />}>
        <Layout>
          <Switch>
            {Private.map((R, k) => {
              return <PrivateRoute key={k} {...R} />;
            })}
            {Auth.map((R, k) => {
              return <AuthRoute key={k} {...R} />;
            })}

            {Public.map((R, k) => {
              return <PublicRoute key={k} {...R} />;
            })}
            {Common.map((R, k) => {
              return <CommonRoute key={k} {...R} />;
            })}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export const AppRouter = RouterConfig;
