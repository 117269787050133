import React from "react";
import {
  CheckSizeAvailable,
  FindProductAvailSize,
  getImage,
  SHOPPING_ID,
  // warnNotify,
} from "../../util";
import { MinusSquareFilled, PlusSquareFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { cartItemQuantityUpdate } from "../../store/modules";
// import {
//   FETCH_PRODUCT_AVAILABLE,
//   SHOPPING_CART_QUANTITY_UPDATE,
// } from "../../graphql/modules";
// import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useState } from "react";
import productType from "../../constants/productType";

const SingleItem = ({
  item,
  deleteCart,
  // fetchItemFromApi = [],
  cartProducts = [],
  preitem = false,
  cartItem,
  limitQuantity,
}) => {
  const dispatch = useDispatch();
  const [limitMessage, setLimitMessage] = useState("");
  const [cartQuantity, setCartQuantity] = useState(item?.quantity);

  // shopping cart quantity update api
  // const [UpdateCartQuantity, { loading }] = useMutation(
  //   SHOPPING_CART_QUANTITY_UPDATE
  // );

  const editCartQuantity = async (rules) => {
    let product = FindProductAvailSize(cartProducts, item);
    const allSizeCount = CheckSizeAvailable(cartItem, item);
    let quantity = item.quantity;
    if (item?.part === productType.fullSet && !item?.isAccessories) {
      quantity = Math.max(allSizeCount?.topCnt, allSizeCount?.bottomCnt);
    } else if (item?.part === productType.top) {
      quantity = allSizeCount.topCnt;
    } else if (item?.part === productType.bottom) {
      quantity = allSizeCount.bottomCnt;
    }
    if (rules === "plus") {
      // check it is preitems or not
      if (preitem) {
        quantity += 1;
        setCartQuantity(quantity);
      } else {
        if (product?.quantity <= quantity) {
          setLimitMessage("You reached the limit quantity for this product");
          return;
        } else {
          quantity += 1;
          setLimitMessage("");
          setCartQuantity(quantity);
        }
      }
    } else {
      if (quantity > 1) {
        quantity -= 1;
        setCartQuantity(quantity);
      } else {
        return;
      }
      setLimitMessage("");
    }

    dispatch(cartItemQuantityUpdate(item, quantity, preitem));
  };
  const handleEditQuantity = (e) => {
    const { value } = e.target;
    let numbers = /^[0-9]+$/;
    if (!value || value === "0") {
      setCartQuantity(value);
      return "";
    }
    if (numbers.test(value)) {
      let product = FindProductAvailSize(cartProducts, item);
      if (product?.quantity < value && !preitem) {
        setLimitMessage("You reached the limit quantity for this product");
        setCartQuantity(product?.quantity);
        dispatch(cartItemQuantityUpdate(item, product?.quantity, preitem));
      } else {
        setLimitMessage("");
        setCartQuantity(value);
        dispatch(cartItemQuantityUpdate(item, Number(value), preitem));
      }
    }
  };

  const price =
    (item?.part === productType.fullSet
      ? item?.price
      : item?.[item?.part?.toLowerCase() + "Price"]) * item.quantity;
  const totalPrice = item?.haveDiscount
    ? parseFloat(price - (price * item?.discount) / 100).toFixed(2)
    : parseFloat(price).toFixed(2);
  return (
    <div className="drawer-cart-item-list">
      <hr />
      <div className="row">
        <div className="col-md-4 col_35">
          <div className="cart-product-img">
            <img
              src={getImage(Array.isArray(item?.gallery) && item?.gallery[0])}
              alt="Cart Item"
            />
          </div>
        </div>
        <div className="col-md-6 col_50_50">
          <div className="cart-product-content">
            <div className="cart-product-title">
              <span>
                {item?.name +
                  ` ${
                    item?.part === productType.fullSet ? "" : " - " + item?.part
                  }`}
              </span>
            </div>
            <div className="cart-product-subtitle">
              {item?.style && (
                <p
                  className="d-flex align-items-center"
                  style={{ fontSize: "13px" }}
                >
                  Style: <span className="ms-1">{item?.style}</span>
                </p>
              )}
              <p
                className="d-flex align-items-center"
                style={{ fontSize: "13px" }}
              >
                Quantity:
                <MinusSquareFilled
                  style={{
                    margin: "0 5px",
                    fontSize: "18px",
                    cursor: item?.quantity < 2 ? "unset" : "pointer",
                    color: item?.quantity < 2 ? "gray" : "#3d3d3d",
                  }}
                  disabled={item?.quantity < 2}
                  onClick={() => editCartQuantity("minus")}
                  id={SHOPPING_ID}
                />
                <input
                  value={cartQuantity}
                  type="number"
                  style={{ width: "30px", height: "20px", textAlign: "center" }}
                  onChange={handleEditQuantity}
                  onBlur={(e) => {
                    if (!cartQuantity || cartQuantity === "0") {
                      handleEditQuantity({ target: { value: item?.quantity } });
                    }
                  }}
                />
                <PlusSquareFilled
                  style={{
                    marginLeft: "5px",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => editCartQuantity("plus")}
                  id={SHOPPING_ID}
                />
              </p>

              {(item?.topName || item?.bottomName) && (
                <p style={{ fontSize: "13px" }}>
                  Sizes: {item?.topName && item?.topName}
                  {item?.bottomName && item?.topName && "/"}
                  {item?.bottomName && item?.bottomName}
                </p>
              )}
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                Price:{" "}
                {item?.haveDiscount ? (
                  <span>
                    <span
                      style={{
                        color: "#3d3d3d",
                        fontSize: "13px",
                        fontWeight: "400",
                        marginRight: "5px",
                      }}
                    >
                      {item?.bogo
                        ? totalPrice - totalPrice / item.quantity
                        : totalPrice}{" "}
                      USD
                    </span>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#3d3d3d",
                      }}
                    >
                      <strike>
                        {parseFloat(
                          item?.bogo
                            ? totalPrice - totalPrice / item.quantity
                            : item.price
                        ).toFixed(2)}{" "}
                        USD
                      </strike>
                    </span>
                    <div
                      style={{
                        background: "#fff",
                        padding: "0px 5px 0px",
                        border: "0.5px solid #3d3d3d",
                        fontSize: "13px",
                        fontWeight: "300",
                        marginRight: "10px",
                        color: "#3d3d3d",
                        display: "inline-block",
                      }}
                    >
                      -{item?.discount}%
                    </div>
                  </span>
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      display: "inline-block",
                    }}
                  >
                    {item?.bogo
                      ? totalPrice - totalPrice / item.quantity
                      : totalPrice}{" "}
                    USD
                  </p>
                )}
              </div>
              {!item?.isAccessories && (
                <div
                  className=""
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Color:{" "}
                  {/* <div
                    className="mx-2"
                    style={{
                      background:
                        item?.part === productType.fullSet
                          ? `linear-gradient(90deg, ${item?.colorDetails?.topColor} 50%, ${item?.colorDetails?.bottomColor} 50%)`
                          : `${item?.colorDetails[item.part.toLowerCase()+'Color']}`,
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      border: "1px solid #2d2d2d",
                      boxShadow: " 0 0 0 2px #f3f5f6 inset",
                      boxSizing: "border-box",
                      cursor: "pointer",
                    }}
                  /> */}
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                  >
                    {item?.part === productType.fullSet
                      ? item?.colorDetails?.topColorName ===
                        item?.colorDetails?.bottomColorName
                        ? item?.colorDetails?.topColorName
                        : `${item?.colorDetails?.topColorName}, ${item?.colorDetails?.bottomColorName}`
                      : ""}
                    {item?.part === "TOP" &&
                      (item?.colorDetails?.topColorName || "White")}
                    {item?.part === "BOTTOM" &&
                      (item?.colorDetails?.bottomColorName || "White")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-2 col_15">
          <div
            className="clear-cart-item"
            onClick={() => deleteCart(item)}
            id={SHOPPING_ID}
          >
            <span>
              <img src="/images/cancel.svg" alt="Clear" />
            </span>
          </div>
        </div>
      </div>
      <p style={{ textAlign: "center", marginTop: "12px" }}>{limitMessage}</p>
    </div>
  );
};

export default SingleItem;
