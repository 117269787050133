import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  // ADD_TO_SHOPPING_CART,
  CLEAR_ALL_SHOPPING_CART,
  DELETE_SINGLE_CART,
  FETCH_DISCOUNT_ON_PURCHASE,
  FETCH_PRODUCT_AVAILABLE,
} from "../../graphql/modules";
import {
  // addItemToCartFromApi,
  clearAllItemFromCart,
  deleteItemFromCart,
  deleteItemFromCartPreOrder,
  // updateBogo,
} from "../../store/modules";
import { Fragment } from "react";
import SingleItem from "./SingleItem";
import { useEffect } from "react";

import {
  SHOPPING_ID,
  totalOrderPrice,
  totalRegularOrderPrice,
} from "../../util";
import { CartSummary } from "./CartSummery";

const CartItem = ({
  openShoppingCart,
  cartLoading,
  // fetchItemFromApi,
  shoppingClassName,
}) => {
  const user = useSelector((state) => state.auth);
  const {
    items: cartItem = [],
    preItems = [],
    cartQuantityLimit,
  } = useSelector((state) => state.cart);
  // const [purchaseDiscount, setPurchaseDiscount] = useState(0);
  const [purchaseDiscountPre, setPurchaseDiscountPre] = useState(0);
  const preSubtotal = totalOrderPrice(preItems);
  const cartSubtotal = totalOrderPrice(cartItem);

  const dispatch = useDispatch();
  // clear cart
  const [ClearCartMutation] = useMutation(CLEAR_ALL_SHOPPING_CART);
  const clearCartApi = async () => {
    try {
      await ClearCartMutation();
    } catch (error) {
      console.log(error.message);
    }
  };
  const clearCart = async () => {
    dispatch(clearAllItemFromCart());
    if (user.isAuthenticated) {
      clearCartApi();
    }
  };

  // delete cart
  const [SingleCartDeleteMutation] = useMutation(DELETE_SINGLE_CART);
  const deleteSingleCart = async (item) => {
    try {
      SingleCartDeleteMutation({
        variables: {
          cartId: item?.cartId || "",
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  const deleteCart = async (item) => {
    dispatch(deleteItemFromCart(item));
    if (user.isAuthenticated) {
      deleteSingleCart(item);
    }
  };

  const deleteCartPreOrder = async (item) => {
    dispatch(deleteItemFromCartPreOrder(item));
    if (user.isAuthenticated) {
      deleteSingleCart(item);
    }
  };

  const [FetchDiscounts, { data: overDiscount }] = useLazyQuery(
    FETCH_DISCOUNT_ON_PURCHASE
  );
  const allOverPriceDiscount = overDiscount?.FetchOrderDiscount?.result;

  useEffect(() => {
    if (shoppingClassName) {
      FetchDiscounts();
    }
  }, [shoppingClassName]);

  useEffect(() => {
    if (allOverPriceDiscount?.length) {
      // setPurchaseDiscount(
      //   totalRegularOrderPrice(cartItem, allOverPriceDiscount)
      // );
      setPurchaseDiscountPre(
        totalRegularOrderPrice(preItems, allOverPriceDiscount)
      );
    }
  }, [allOverPriceDiscount, preItems]);

  const [FetchSingleProduct, { data }] = useLazyQuery(FETCH_PRODUCT_AVAILABLE);

  useEffect(() => {
    const productIds = cartItem?.map((prod) => prod._id);
    if (productIds.length > 0) {
      FetchSingleProduct({
        variables: {
          productIds,
        },
      });
    }
  }, [cartItem]);

  // useEffect(() => {
  //   dispatch(updateBogo());
  // }, [cartItem.length, preItems.length]);

  const cartProducts = data?.FetchBulkProduct?.result || [];
  let count = 0;
  cartItem.forEach((item) => {
    count = count + item.quantity;
  });
  preItems.forEach((item) => {
    count = count + item.quantity;
  });

  // console.log(cartItem)

  return (
    <React.Fragment>
      {count > 0 && (
        <button
          className="btn btn-common margin_t_b_zero"
          onClick={clearCart}
          id={SHOPPING_ID}
        >
          Clear Cart
        </button>
      )}
      {cartItem?.length > 0 && (
        <Fragment>
          <hr />
          <div
            className="mb-3 mt-3 text-center"
            style={{ fontWeight: "700", color: "#3d3d3d" }}
          >
           READY TO SHIP
          </div>
        </Fragment>
      )}
      {cartItem?.map((item, index) => (
        <SingleItem
          key={index}
          item={item}
          deleteCart={deleteCart}
          limitQuantity={cartQuantityLimit}
          // fetchItemFromApi={fetchItemFromApi}
          cartProducts={cartProducts}
          cartItem={cartItem}
        />
      ))}
      {preItems?.length > 0 && (
        <Fragment>
          <hr />
          <div
            className="mb-3 mt-3 text-center"
            style={{ fontWeight: "700", color: "#3d3d3d" }}
          >
            SHIP IN 2 WEEKS
          </div>
        </Fragment>
      )}
      {preItems?.map((item, index) => (
        <SingleItem
          key={index}
          item={item}
          deleteCart={deleteCartPreOrder}
          preitem={true}
          limitQuantity={cartQuantityLimit}
          cartProducts={cartProducts}
          cartLoading={cartLoading}
          cartItem={preItems}
        />
      ))}

      {/* <!-- Shopping cart calculation --> */}
      {(preItems?.length > 0 || cartItem?.length > 0) && (
        <CartSummary
          cartSubtotal={preSubtotal + cartSubtotal}
          purchaseDiscount={purchaseDiscountPre}
          user={user}
          openShoppingCart={openShoppingCart}
          orderType={
            preItems?.length > 0 && cartItem?.length > 0
              ? "all-order"
              : preItems?.length > 0
              ? "pre-order"
              : "regular"
          }
        />
      )}
      {count < 1 && <p className="text-center">You have no cart item</p>}
    </React.Fragment>
  );
};

export default CartItem;
